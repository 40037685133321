import React, { useState, useEffect } from 'react'
import DownloadPdf from '../../../components/DownloadPdf'
import NumberFormatter from '../../../components/Formarts';
import TooltipModal from '../../../components/modals/ToolTipModal';
import BarChart from '../../../components/charts/BarChart';
import UpperFormatt from '../../../components/UpperFormatt';


const TableAssurance = ({ data }) => {

  const [tax, setTax] = useState("IMPUESTO")
  const [extraTax, setExtraTax] = useState("IMPUESTO EXTRA")
  
  useEffect(() => {
    const taxName = JSON.parse(localStorage.getItem("customizations"))
    setTax(taxName[0].custom[0].impuesto_vehicular)
    setExtraTax(taxName[0].custom[0].impuesto_extra)
  })
  

  if (!data || data.length <= 0 || data.error || data === null) {
    return <p className="subtitle">Sin parámetros asignados</p>;
  }
  return (
    <div>{data.length > 0 && (
      <>
        <div className="father-box">
          {data[0]['electricRollTax'] === 0 && data[0]['fuelRollTax'] === 0  ? <></> :
            <div className="child-box shadow-left">
              <h4 className="subtitle" style={{textTransform: "uppercase"}} ><UpperFormatt string={tax} /> <TooltipModal text={'Impuesto anual que se aplica a los vehículos automotores nuevos, usados y los que ingresen temporalmente al territorio Nacional'} title={'Impuesto de circulacíon'} /></h4>
              <br />
              <h4>Eléctrico</h4>
              <p className='big-result-text'>$ <NumberFormatter number={data[0]['electricRollTax']} /></p>
              <hr />
              <h4>Combustión</h4>
              <p className='big-result-text'>$ <NumberFormatter number={data[0]['fuelRollTax']} /> </p>
            </div>}
          {data[0]['SOAT']['electric'] === 0 ? <></> :
            <div className="child-box shadow-left">
              <h4 className="subtitle">SOAT <TooltipModal text={'Seguro obligatorio que todo usuario con un vehículo automotor debe adquirir para la circulación de este'} title={'SOAT'} /></h4>
              <br />
              <h4>Eléctrico</h4>
              <p className='big-result-text'>$ <NumberFormatter number={data[0]['SOAT']['electric']} /></p>
              <hr />
              <h4>Combustión</h4>
              <p className='big-result-text'>$ <NumberFormatter number={data[0]['SOAT']['fuel']} /></p>
            </div>
          }
        </div>
        <div className="father-box">
          <div className="child-box shadow-left">
            <h5>PROYECCIÓN DE IMPUESTOS Y SEGUROS PARA VEHÍCULO ELÉCTRICO</h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'} /></td>
                </tr>
                {data[0].electricAnnualCost.valueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4><UpperFormatt string={extraTax} /> <TooltipModal text={'Impuesto anual que se aplica a los vehículos automotores nuevos, usados y los que ingresen temporalmente al territorio Nacional'} title={'Impuesto de circulacíon'} /></h4>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'} /></td>
                </tr>
                {data[0].electricAnnualCost.extraTaxProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className='big-result-text'>Total: $ <NumberFormatter number={data[0]['electricAnnualCost']['total']} /></h4>
          </div>
          <div className="child-box shadow-left">
            <h5>PROYECCIÓN DE IMPUESTOS Y SEGUROS PARA VEHÍCULO A COMBUSTIÓN</h5>
            <br />
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'} /></td>
                </tr>
                {data[0].fuelAnnualCost.valueProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4><UpperFormatt string={extraTax} /> <TooltipModal text={'Impuesto anual que se aplica a los vehículos automotores nuevos, usados y los que ingresen temporalmente al territorio Nacional'} title={'Impuesto de circulacíon'} /></h4>
            <table className="table table-striped-columns p-2">
              <tbody>
                <tr>
                  <td className="table-head">Año</td>
                  <td className="table-head">Proyección <TooltipModal text={'Este valor comprende el impuesto de circulación, el valor del SOAT y el seguro todo riesgo ingresado en la ventana de parámetros'} title={'Proyección'} /></td>
                </tr>
                {data[0].fuelAnnualCost.extraTaxProyected.map((datas, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>$ <NumberFormatter number={datas} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <h4 className='big-result-text'>Total: $ <NumberFormatter number={data[0]['fuelAnnualCost']['total']} /></h4>
          </div>
        </div>
        <br />
        <div className="shadow-left">
          <BarChart data={{
            categories: ['Aseguramiento'],
            series: [
              {
                name: 'Eléctrico',
                data: [parseFloat(data[0]['electricAnnualCost']['total'])],
              },
              {
                name: 'Combustión',
                data: [parseFloat(data[0]['fuelAnnualCost']['total'])],
              }
            ],
          }} />
        </div>
      </>
    )}
      <br /><br />
    </div>
  )
}

export default TableAssurance