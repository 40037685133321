import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import Spinner from '../../components/spinner/Spinner';
import loaderTotals from '../../services/loaderTotals';
import TitleComponent from '../../components/titleComponent/TitleComponent';
import TableTotals from './components/TableTotals';


const Totals = () => {

    const params = useParams()
    const id = params.id;
    const [cleanDataDetail, setCleanDataDetail] = useState([])
    const [loading, setLoading] = useState(false)
    const storedParams = JSON.parse(localStorage.getItem('dataParams'))

    const [names, setNames] = useState()

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem("totals"));
        if (savedData) {
            setCleanDataDetail(savedData);
        } else {
            setCleanDataDetail([]);
        }
    }, []);

    let financialTable = [];
    let financialTotal = 0;
    let fuelFinancialTable = [];
    let fuelFinancialTotal = 0;
    let loan

    let consuptionTable = [];
    let consuptionTotal = 0;
    let fuelConsuptionTable = [];
    let fuelConsuptionTotal = 0;

    let maintenanceTable = [];
    let maintenanceTotal = 0;
    let fuelMaintenanceTable = [];
    let fuelMaintenanceTotal = 0;

    let consumablesTable = [];
    let consumablesTotal = 0;
    let fuelConsumablesTable = [];
    let fuelConsumablesTotal = 0;

    let bateryTable = 0;
    let bateryTotal = 0;

    let assuranceTable = 0;
    let assuranceExtraTaxTable = 0;
    let assuranceTotal = 0;
    let fuelAssuranceTable = 0;
    let fuelAssuranceExtraTaxTable = 0; 
    let fuelAssuranceTotal = 0;

    let productivityTable = 0;
    let productivityTotal = 0;
    let fuelProductivityTable = 0;
    let fuelProductivityTotal = 0;
    let financialData
    let fuelFinancialData


    let anualUse = 5000;
    let yearsUse = 1;

    try {
        const financial = JSON.parse(localStorage.getItem("financial"));
        const consuption = JSON.parse(localStorage.getItem("consuption"));
        const maintenance = JSON.parse(localStorage.getItem("maintenance"));
        const batery = JSON.parse(localStorage.getItem("batery"));
        const assurance = JSON.parse(localStorage.getItem("assurance"));
        const productivity = JSON.parse(localStorage.getItem("productivity"));
        const total_payed = JSON.parse(localStorage.getItem("total_payed"));

        anualUse = storedParams.annualUse;
        yearsUse = storedParams.yearsUse;
        loan = financial[0].loan


        if (loan === "true") {
            //Financial electrical Totals
            //Table
            financialData = financial[0]['amortizationTable']
            for (let index = 0; index < financialData.length; index++) {
                financialTable.push(financial[0]['amortizationTable'][index]['Pago'])
            }

            //Financial fuel Totals
            //Table
            fuelFinancialData = financial[0]['amortizationTableFuel']
            for (let index = 0; index < fuelFinancialData.length; index++) {
                fuelFinancialTable.push(financial[0]['amortizationTableFuel'][index]['Pago'])
            }
        }
        else {
            financialTable.push(0)
            fuelFinancialTable.push(0)
        }

        //Total 
        financialTotal = total_payed.electric;
        fuelFinancialTotal = total_payed.fuel;

        //Consuption Electrical Totals 
        //Table
        const consuptionData = consuption[0]['annual_proyection']['annual_proyection']
        for (let index = 0; index < consuptionData.length; index++) {
            consuptionTable.push(consuptionData[index]['present_value'])
        }
        //Total 
        consuptionTotal = consuption[0]['annual_proyection']['total'];

        //Consuption Fuel Totals 
        //Table
        const fuelConsuptionData = consuption[0]['annual_fuel_proyection']['annual_proyection']
        for (let index = 0; index < fuelConsuptionData.length; index++) {
            fuelConsuptionTable.push(fuelConsuptionData[index]['present_value'])
        }
        //Total 
        fuelConsuptionTotal = consuption[0]['annual_fuel_proyection']['total'];

        //Maintenance Electric Totals 
        //Table
        const maintenanceData = maintenance[0]['maintenanceProyected']['presents']
        for (let index = 0; index < maintenanceData.length; index++) {
            maintenanceTable.push(maintenance[0]['maintenanceProyected']['presents'][index]['data_proyected'])
        }
        //Total 
        maintenanceTotal = maintenance[0]['maintenanceProyected']['totals']

        //Maintenance Fuel Totals 
        //Table
        const fuelMaintenanceData = maintenance[0]['maintenanceFuelProyected']['presents']
        for (let index = 0; index < fuelMaintenanceData.length; index++) {
            fuelMaintenanceTable.push(maintenance[0]['maintenanceFuelProyected']['presents'][index]['data_proyected'])
        }
        //Total
        fuelMaintenanceTotal = maintenance[0]['maintenanceFuelProyected']['totals']

        //Consumables Electrical Totals
        //Table 
        const consumablesData = maintenance[0]['consumablesProyected']['electrical_present_value']
        for (let index = 0; index < consumablesData.length; index++) {
            consumablesTable.push(maintenance[0]['consumablesProyected']['electrical_present_value'][index])
        }
        //Total 
        consumablesTotal = maintenance[0]['consumablesProyected']['total_electrical_proyected'];

        //Consumables Fuel Total
        //Table
        const fuelconsumablesData = maintenance[0]['consumablesProyected']['fuel_present_value']
        for (let index = 0; index < fuelconsumablesData.length; index++) {
            fuelConsumablesTable.push(maintenance[0]['consumablesProyected']['fuel_present_value'][index])
        }
        //Total
        fuelConsumablesTotal = maintenance[0]['consumablesProyected']['total_fuel_proyected'];

        //Batery Totals
        bateryTable = batery[0]['fixValueProyected']['totalFixProyected'];
        bateryTotal = batery[0]['changeBateryTotal'];

        //Asurance Electrical Totals 
        //Table
        assuranceTable = assurance[0]['electricAnnualCost']['valueProyected'];
        assuranceExtraTaxTable = assurance[0]['electricAnnualCost']['extraTaxProyected'];
        //Total 
        assuranceTotal = assurance[0]['electricAnnualCost']['total'];

        //Assurance Fuel Totals 
        //Table 
        fuelAssuranceTable = assurance[0]['fuelAnnualCost']['valueProyected'];
        fuelAssuranceExtraTaxTable = assurance[0]['fuelAnnualCost']['extraTaxProyected'];
        //Total
        fuelAssuranceTotal = assurance[0]['fuelAnnualCost']['total'];

        //Productivity Electrical Totals 
        //Table 
        productivityTable = productivity[0]['annualLoss']['presentValue'];
        //Total 
        productivityTotal = productivity[0]['annualLoss']['total'];

        //Productivity Fuel Totals 
        //Table 
        fuelProductivityTable = productivity[0]['fuelAnnualLoss']['presentValue'];
        //Total
        fuelProductivityTotal = productivity[0]['fuelAnnualLoss']['total'];


    } catch (error) {
        console.error(error);
    }

    const getDataDetail = async () => {
        setLoading(true)
        const data = await loaderTotals(
            id,
            financialTable,
            financialTotal,
            fuelFinancialTable,
            fuelFinancialTotal,
            loan,

            consuptionTable,
            consuptionTotal,
            fuelConsuptionTable,
            fuelConsuptionTotal,

            maintenanceTable,
            maintenanceTotal,
            fuelMaintenanceTable,
            fuelMaintenanceTotal,

            bateryTable,
            bateryTotal,

            assuranceTable,
            assuranceExtraTaxTable,
            assuranceTotal,
            fuelAssuranceTable,
            fuelAssuranceExtraTaxTable,
            fuelAssuranceTotal,

            productivityTable,
            productivityTotal,
            fuelProductivityTable,
            fuelProductivityTotal,

            consumablesTable,
            consumablesTotal,
            fuelConsumablesTable,
            fuelConsumablesTotal
        )

        localStorage.setItem('totals', JSON.stringify(data))
        setCleanDataDetail(JSON.parse(localStorage.getItem('totals')))
        setLoading(false)
    }

    useEffect(() => {
        getDataDetail()
        setLoading(false)
    }, []);

    return (
        <div>
            <TitleComponent text={'Resultados finales'} />
            <div className='container'>
                {loading ? <Spinner /> : <TableTotals data={cleanDataDetail} yearsUse={yearsUse} anualUse={anualUse} />}
            </div>
        </div>
    )
}

export default Totals;